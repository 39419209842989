export enum MediatorEventType {
  SELECT_TAB = "RACE_RESULTS_SELECT_TAB",
  CHANGE_TRACK = "RACE_RESULTS_NEXT_RACE_CHANGE_TRACK"
}

// Payload type data
export interface ResultType {
  type: MediatorEventType;
  payload: {
    tabName: string;
    raceResults: boolean;
  };
}

export interface ResultChangeTrackNextRace {
  type: MediatorEventType;
  payload: {
    actionName: "Next Race" | "Change Track";
    destinationUrl: string;
  };
}
