// @flow

import React, { PureComponent } from "react";
// $FlowFixMe
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { get } from "lodash";
import TVGConf from "@tvg/conf";
import type { Props, State } from "@tvg/types/Login";
import { parseCAPIMessage } from "@tvg/utils/capiUtils";
import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import {
  getAccountNumber,
  getBalance,
  getEmail,
  getResidenceState
} from "@urp/store-selectors";
import { getIsContentCardsOpen } from "./selectors/modal";

const LoginControllerAW = loadable(() => import("./LoginControllerAW"));
const LoginController = loadable(() => import("./LoginController"));

export class LoginModal extends PureComponent<Props, State> {
  render() {
    return this.props.enableAWFlows ? (
      <LoginControllerAW
        history={this.props.history}
        location={this.props.location}
      />
    ) : (
      <LoginController history={this.props.history} />
    );
  }
}

export default connect((store) => ({
  user: {
    firstName: get(store, "userData.user.firstName"),
    lastName: get(store, "userData.user.lastName"),
    isLogged: get(store, "userData.logged"),
    returningUser: get(store, "userData.returningUser", false),
    balance: getBalance(store),
    balancePoller: get(store, "userData.balancePoller"),
    wagerProfile: get(store, "userData.user.profile"),
    accountNumber: getAccountNumber(store),
    userName: get(store, "userData.user.userName"),
    homeState: getResidenceState(store),
    emailAddress: getEmail(store)
  },
  androidGpsAllowedStates: get(
    store,
    "capi.messages.androidGpsAllowedStates",
    ""
  ),
  splashError: get(store, "locationSplash.error", null),
  isLocationSplashOpen: get(store, "locationSplash.isOpen", false),
  isLocationRequired: get(store, "locationSplash.isLocationRequired", false),
  onLocationGet: get(store, "locationSplash.onLocationGet", null),
  brazePoller: get(store, "brazeData.brazePoller", 60),
  touchId: {
    initEnabled: get(store, "ios.init.touchIdEnabled", false),
    enabled: get(store, "ios.touchId.touchIdEnabled", false),
    accountId: get(store, "ios.touchId.accountId", ""),
    userChangingTouchId: get("store.ios.userChangingTouchId", false),
    token: get(store, "ios.touchId.token", "")
  },
  geolocation: get(store, "geolocation"),
  contentCardsOpen: getIsContentCardsOpen(
    store,
    TVGConf().device === "desktop"
  ),
  loginModal: store.loginModal,
  geopacketUsage: get(
    store,
    "capi.featureToggles.geopacketUsage",
    get(store, "header.features.geopacketUsage", false)
  ),
  locationRequired: get(
    store,
    "capi.messages.statesWithLocationRequired",
    '["ca", "nj", "az"]'
  ),
  promos: {
    gotPromos: get(store, "userData.gotPromos"),
    loadingPromos: get(store, "userData.loadingPromos")
  },
  useAndroidGpsAllowedStates: get(
    store,
    "capi.featureToggles.useAndroidGpsAllowedStates",
    false
  ),
  promoOnboardingToggle: getPromosOnboardingToggle(store),
  promoOnboardingPollerTime: getPromoOnboardingPollerTime(store),
  enableAWFlows: get(store, "capi.featureToggles.enableAWFlows", false),
  // $FlowFixMe
  allowedStates: Object.keys(
    parseCAPIMessage(store, "capi.messages.stateSelectorListFdr", {})
  )
}))(LoginModal);
