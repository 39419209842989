import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  START = "LOGIN_MODAL_START",
  ATTEMPT = "LOGIN_MODAL_LOGIN_ATTEMPT",
  SUCCESS = "LOGIN_MODAL_SUCCESS",
  ERROR = "LOGIN_MODAL_LOGIN_ERROR",
  FIELD_COMPLETE = "LOGIN_MODAL_FIELD_COMPLETE",
  SELECT_STATE = "LOGIN_MODAL_SELECT_STATE",
  FORGOT_CREDENTIALS = "LOGIN_MODAL_FORGOT_CREDENTIALS",
  LOCATION_MODAL_VIEWED = "LOGIN_MODAL_LOCATION_MODAL_VIEWED",
  LOCATION_MODAL_CLOSED = "LOGIN_MODAL_LOCATION_MODAL_CLOSED",
  LOCATION_MODAL_CTA_CLICKED = "LOGIN_MODAL_LOCATION_MODAL_CTA_CLICKED",
  LOGIN_SERVICE_LOADED = "OPEN_LOGIN_SUCCESS",
  LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPENED",
  LOGIN_ERROR_MODAL_VIEWED = "LOGIN_MODAL_DEFAULT_ERROR_VIEWD",
  LOGIN_ERROR_MODAL_CTA_CLICKED = "LOGIN_MODAL_DEFAULT_ERROR_CTA_CLICKED",
  LOGIN_ERROR_MODAL_CLOSED = "LOGIN_MODAL_DEFAULT_ERROR_CLOSED"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    accountId: string;
    error: unknown;
    field: string;
    state: string;
    open: boolean;
    linkText?: string;
    linkUrl?: string;
    module?: string;
  };
}
