import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.START, () =>
    track<EventProps>(EVENT_NAME.START, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.MODULE]: "Modal"
    })
  );

  mediator.base.subscribe(MediatorEventType.ATTEMPT, () =>
    track<EventProps>(EVENT_NAME.ATTEMPT, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.MODULE]: "Modal"
    })
  );

  mediator.base.subscribe(MediatorEventType.SUCCESS, (data: EventData) =>
    track<EventProps>(EVENT_NAME.SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.MODULE]: "Modal",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [BaseEventProps.REGISTRATION_STATUS]: "Registered"
    })
  );

  mediator.base.subscribe(MediatorEventType.ERROR, (data: EventData) =>
    track<EventProps>(EVENT_NAME.ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.error"),
      [BaseEventProps.MODULE]: "Modal"
    })
  );

  mediator.base.subscribe(MediatorEventType.FIELD_COMPLETE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.FIELD_COMPLETE, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.field"),
      [BaseEventProps.MODULE]: "Modal"
    })
  );

  mediator.base.subscribe(MediatorEventType.SELECT_STATE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.SELECT_STATE, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.state"),
      [BaseEventProps.MODULE]: "Modal"
    })
  );

  mediator.base.subscribe(MediatorEventType.FORGOT_CREDENTIALS, () =>
    track<EventProps>(EVENT_NAME.FORGOT_CREDENTIALS, {
      [BaseEventProps.EVENT_CATEGORY]: "Login",
      [BaseEventProps.MODULE]: "Header"
    })
  );

  mediator.base.subscribe(MediatorEventType.LOCATION_MODAL_VIEWED, () =>
    track<EventProps>(EVENT_NAME.LOCATION_MODAL_VIEWED)
  );

  mediator.base.subscribe(MediatorEventType.LOCATION_MODAL_CLOSED, () =>
    track<EventProps>(EVENT_NAME.LOCATION_MODAL_CLOSED, {
      [BaseEventProps.MODULE]: "location_permission"
    })
  );

  mediator.base.subscribe(
    MediatorEventType.LOCATION_MODAL_CTA_CLICKED,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.LOCATION_MODAL_CTA_CLICKED, {
        [BaseEventProps.LINK_URL]: get(data, "payload.linkUrl"),
        [BaseEventProps.LINK_TEXT]: get(data, "payload.linkText"),
        [BaseEventProps.MODULE]: "location_permission"
      })
  );

  mediator.base.subscribe(MediatorEventType.LOGIN_SERVICE_LOADED, () =>
    track<EventProps>(EVENT_NAME.LOGIN_SERVICE_LOADED, {})
  );

  mediator.base.subscribe(
    MediatorEventType.LOGIN_MODAL_OPEN,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.LOGIN_MODAL_OPEN, {
        [BaseEventProps.MODULE]: get(data, "payload.module", "join_bar")
      })
  );

  // When “An error has occured” module appears on the screen for a user
  mediator.base.subscribe(MediatorEventType.LOGIN_ERROR_MODAL_VIEWED, () =>
    track<BaseEventProps>(EVENT_NAME.LOGIN_ERROR_VIEW, {
      [BaseEventProps.MODULE]: "default_error"
    })
  );

  // When a user clicks “contact support” from unknown error module
  mediator.base.subscribe(
    MediatorEventType.LOGIN_ERROR_MODAL_CTA_CLICKED,
    (data: EventData): void =>
      track<BaseEventProps>(EVENT_NAME.LOGIN_ERROR_CTA_CLICKED, {
        [BaseEventProps.MODULE]: "default_error",
        [BaseEventProps.LINK_TEXT]: "contact support",
        [BaseEventProps.LINK_URL]: data.payload.linkUrl
      })
  );

  // When user 'X' out from “Default error” module
  mediator.base.subscribe(MediatorEventType.LOGIN_ERROR_MODAL_CLOSED, () =>
    track<BaseEventProps>(EVENT_NAME.LOGIN_ERROR_CLOSED, {
      [BaseEventProps.MODULE]: "default_error"
    })
  );
};
