import wro from "./wro";
import wtx from "./wtx";
import ufc from "./ufc";
import uam from "./uam";
import uwt from "./uwt";
import rwd from "./rwd";
import usa from "./usa";
import pes from "./pes";
import pro from "./pro";
import fdr from "./fdr";
import wrc from "./wrc";
import capi from "./capi";
import pxp from "./pxp";
import gas from "./gas";
import pay from "./pay";
import uc from "./uc";
import rcn from "./rcn";
import seo from "./seo";

const SERVICES_MODULES = {
  wro,
  wtx,
  ufc,
  uam,
  uwt,
  rwd,
  usa,
  pes,
  pro,
  fdr,
  wrc,
  capi,
  pxp,
  gas,
  pay,
  uc,
  rcn,
  seo
};

export default SERVICES_MODULES;
