// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import style from "./style.css";

type Props = {
  changeActiveTab: () => void
}

class TabSelector extends Component {

  constructor() {
    super();
    this.state = {
      isSignupTabActive: false,
      isloginTabActive: true,
    };
    this.onTabClickHandler = this.onTabClickHandler.bind(this);
  }

  state: {
    isSignupTabActive: boolean,
    isloginTabActive: boolean
  };

  onTabClickHandler: () => void;

  onTabClickHandler(e: Event) {
    e.preventDefault();
    this.setState({
      isSignupTabActive: !this.state.isSignupTabActive,
      isloginTabActive: !this.state.isloginTabActive,
    });
    this.props.changeActiveTab();
  }
  props: Props;

  render() {
    const signupTabStyle = classNames({
      [style.tabSelector]: true,
      [style.activeTab]: this.state.isSignupTabActive,
    });

    const loginTabStyle = classNames({
      [style.tabSelector]: true,
      [style.activeTab]: this.state.isloginTabActive,
    });

    return (
      <div className={style.tabSelectorContainer}>
        <div className={signupTabStyle}>
          <button
            className={style.btnTab}
            onClick={this.onTabClickHandler}
          >
            new to tvg?
          </button>
        </div>
        <div className={loginTabStyle}>
          <button
            className={style.btnTab}
            onClick={this.onTabClickHandler}
          >
            login
          </button>
        </div>
      </div>
    );
  }


}

export default TabSelector;
