import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PROMO_CODE = "Promo Code"
}

export type EventsProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PAGE_VIEW = "RACETRACKS:PAGE_VIEW",
  TRACK_CLICK = "RACETRACKS:TRACK_CLICK",
  RACE_CLICK = "RACETRACKS:RACE_CLICK",
  ADD_FAVORITE_CLICK = "RACETRACKS:ADD_FAVORITE_CLICK",
  ADD_FAVORITE_SUCCESS = "RACETRACKS:ADD_FAVORITE_SUCCESS",
  SEE_ALL_TODAYS_RACING = "RACETRACKS:SEE_ALL_TODAYS_RACING",
  RETURN_TO_RACETRACKS = "RACETRACKS:RETURN_TO_RACETRACKS"
}

export type RaceTracksEventData = {
  type: MediatorEventType;
  payload: {
    trackName?: string;
    raceNumber?: number;
    mtp?: number;
    destinationUrl?: string;
  };
};

export type PageViewEventData = {
  type: MediatorEventType;
  payload: {
    location?: Location;
    loginStatus?: string;
    residenceState?: string;
    accountId?: string;
    runnerType?: string;
  };
};
