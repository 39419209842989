// @flow
import React from 'react';
import classNames from 'classnames';
import style from "./style.css";

// Icons
import DeviceIcon from './svg/devices_icon.svg';
import ReplayIcon from './svg/replays_icon.svg';
import SecurityIcon from './svg/security_icon.svg';
import TicketsIcon from './svg/tickets_icon.svg';

// Components
import SignupHeader from './SignupHeader/index.jsx';
import ItemAdvantage from './SignupItemAdvantage/index.jsx';
import SignupFooter from './SignupFooter/index.jsx';

type Props = {
  mobile?: boolean
}

const SignupBlock = (props: Props) => {
  const signupBlock = classNames({
    [style.signupBlock]: true,
    [style.signupBlockMobile]: props.mobile,
  });

  return (
    <div className={signupBlock}>
      <SignupHeader mobile={props.mobile} />
      <div className={style.signupBlockContainer}>
        <ItemAdvantage icon={SecurityIcon} text="US Based, Safe, Regulated, Legal & Licensed" />
        <ItemAdvantage icon={TicketsIcon} text="Over 100m bets placed per year" />
        <ItemAdvantage icon={DeviceIcon} text="Bet from any device" />
        <ItemAdvantage icon={ReplayIcon} text="Stream TVG Network in HD + watch over 300 tracks anywhere" />
      </div>
      {
        !props.mobile ?
          <div className={style.bglayer} /> : null
      }
      <SignupFooter mobile={props.mobile} />
    </div>
  );
};

SignupBlock.defaultProps = {
  mobile: false,
};

export default SignupBlock;
