// @flow
import React from 'react';
import style from "./style.css";

type Props = {
  text: string
}

const ItemAdvantage = (props: Props) => (
  <div className={style.advantageBlock}>
    <div className={style.iconAdvantage}>
      <props.icon />
    </div>
    <div className={style.textAdvantage}>
      {props.text}
    </div>
  </div>
  );

export default ItemAdvantage;
