import tvgConf from "@tvg/conf";
import type {
  RewardsHistoryResponse,
  RewardsInfoResponse,
  RewardsRedeemInfo
} from "@tvg/ts-types/WagerRewards";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const rwdService: string = "service.rwd";

const getRwdServiceUrl = (accountNumber: string): string =>
  `${tvgConf().config(rwdService)}/users/${accountNumber}`;

export const getRewardsInfo = (
  accountNumber: string
): Promise<{ data: RewardsInfoResponse; status: number }> =>
  requester()({
    url: `${getProtocol()}${getRwdServiceUrl(accountNumber)}/rewards/info`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

export const getRewardsHistory = (
  accountNumber: string,
  startDate: string,
  endDate: string,
  page: number = 1,
  recordsPerPage: number = 25,
  ascending: boolean = false
): Promise<{ data: RewardsHistoryResponse; status: number }> =>
  requester()({
    url: `${getProtocol()}${getRwdServiceUrl(
      accountNumber
    )}/rewards/history?recordsPerPage=${recordsPerPage}&page=${page}&ascending=${ascending.toString()}&endDate=${endDate}&startDate=${startDate}`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

export const redeemPoints = (
  accountNumber: string,
  points: number
): Promise<{ data: RewardsRedeemInfo; status: number }> =>
  requester()({
    url: `${getProtocol()}${getRwdServiceUrl(
      accountNumber
    )}/rewards/${points}/redeem`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

export const enroll = (
  accountNumber: string
): Promise<{ data: { message: string }; status: number }> =>
  requester()({
    url: `${getProtocol()}${getRwdServiceUrl(accountNumber)}/rewards/enroll`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const RWD_SERVICE = {
  getRewardsInfo,
  getRewardsHistory,
  redeemPoints,
  enroll
};

export default RWD_SERVICE;
