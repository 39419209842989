import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  MediatorEventType,
  MultiLegCreateBet,
  MyBets,
  MyBetsBase,
  MyBetsDelete,
  MyBetsDeleteBetError,
  MyBetsFilterHandling,
  MyBetsStartup,
  RepeatBetEventData
} from "./types";
import { EVENT_NAME, LABELS } from "./constants";
import { convertMyBetsFilterActive, setFirstLetterCapital } from "../../utils";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.TAB_SELECT, (data: MyBets) => {
    track<BaseEventProps>(EVENT_NAME.OPENED, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.tab"),
      [BaseEventProps.MODULE]: "Bets Modal"
    });
  });

  mediator.base.subscribe(MediatorEventType.STARTUP, (data: MyBetsStartup) => {
    track<BaseEventProps>(EVENT_NAME.STARTUP, {
      [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBetsCounter", 0),
      [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBetsCounter", 0)
    });
  });

  mediator.base.subscribe(
    MediatorEventType.FILTERS_HANDLING,
    (data: MyBetsFilterHandling) => {
      track<BaseEventProps>(
        `${
          get(data, "payload.isOpening") ? EVENT_NAME.OPENED : EVENT_NAME.CLOSED
        } Filter`,
        {
          [BaseEventProps.EVENT_CATEGORY]: "My Bets",
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.filter"),
          [BaseEventProps.MODULE]: get(data, "payload.tab"),
          [BaseEventProps.FILTER_ACTIVE]: get(
            data,
            "payload.settledTab",
            "None"
          ),
          [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
          [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CLEAR_FILTER_DROPDOWN,
    (data: MyBetsFilterHandling) => {
      track<BaseEventProps>(EVENT_NAME.CLEARED_FILTER_LIST, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.name"),
        [BaseEventProps.MODULE]: "Settled",
        [BaseEventProps.FILTER_ACTIVE]: get(
          data,
          "payload.activeFilter",
          "None"
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SELECT_FILTER_TYPE,
    (data: MyBetsFilterHandling) => {
      track<BaseEventProps>(EVENT_NAME.SELECTED_FILTER, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.name"),
        [BaseEventProps.MODULE]: "Settled",
        [BaseEventProps.FILTER_ACTIVE]: get(
          data,
          "payload.activeFilter",
          "None"
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SELECT_CUSTOM_DATE,
    (data: MyBetsFilterHandling) => {
      track<BaseEventProps>(EVENT_NAME.SELECTED_FILTER_DATE, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: "Custom Date",
        [BaseEventProps.MODULE]: "Settled",
        [BaseEventProps.FILTER_ACTIVE]: "Custom Date",
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CLEAR_ALL_FILTERS,
    (data: MyBetsFilterHandling) => {
      track<BaseEventProps>(EVENT_NAME.CLEARED_ALL_FILTERS, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.MODULE]: "Settled",
        [BaseEventProps.FILTER_ACTIVE]: get(
          data,
          "payload.activeFilter",
          "None"
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.GO_TO_TRACK, (data: MyBets) => {
    track<BaseEventProps>(EVENT_NAME.CLICK_NAVIGATED_TO, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: `${get(data, "payload.trackName")} - ${get(
        data,
        "payload.number"
      )} - ${get(data, "payload.mtp")}`,
      [BaseEventProps.MODULE]: "Bets Modal",
      [BaseEventProps.LINK_URL]: get(data, "payload.url")
    });
  });

  mediator.base.subscribe(
    MediatorEventType.TIMEFRAME_SELECTED,
    (data: MyBets) => {
      const selectedTab =
        get(data, "payload.tab", "") === "ACTIVE" ? "Active" : "Settled";
      const timeframe =
        selectedTab === "Active" ? "None" : get(data, "payload.timeframe", "");
      track<BaseEventProps>(EVENT_NAME.TIMEFRAME_SELECTED_FILTER, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: timeframe,
        [BaseEventProps.MODULE]: selectedTab,
        [BaseEventProps.FILTER_ACTIVE]: timeframe,
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.OPEN_FROM_FOOTER, () => {
    track<BaseEventProps>(EVENT_NAME.OPEN_FOOTER, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "Bets",
      [BaseEventProps.MODULE]: "footer"
    });
  });

  mediator.base.subscribe(MediatorEventType.KEEP_BET, () => {
    track<BaseEventProps>(EVENT_NAME.KEEP_BET, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Delete Bet Modal"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.CONFIRM_DELETE_BET,
    (data: MyBetsDelete) => {
      track<BaseEventProps>(EVENT_NAME.CANCEL_BET_SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.wagerSerialNumber"),
        [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
        [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet"),
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.ERROR_DELETE_BET,
    (data: MyBetsDelete) => {
      track<BaseEventProps>(EVENT_NAME.CANCEL_BET_ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.errorMessage"),
        [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
        [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet"),
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
        [BaseEventProps.MODULE]: "My Bets My Account"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.DELETE_BET, () => {
    track<BaseEventProps>(`${LABELS.DELETE} ${EVENT_NAME.OPENED}`, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: LABELS.DELETE,
      [BaseEventProps.MODULE]: "Bets Modal"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.DELETE_BET_ERROR,
    (data: MyBetsDeleteBetError) => {
      track<BaseEventProps>(EVENT_NAME.DELETE_BET_ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.EVENT_LABEL]: data.payload.errorId,
        [BaseEventProps.MODULE]: "My Bets My Account",
        [BaseEventProps.RUNNER_SELECTION_LIST]: data.payload.runnerSelection,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.BET_ID]: data.payload.betId,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
        [BaseEventProps.BET_TYPE]: data.payload.betType,
        [BaseEventProps.REPEAT_BET]: data.payload.repeatBet
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SHOW_DETAILS,
    (data: MyBetsBase) => {
      track<BaseEventProps>(
        get(data, "payload.show", false)
          ? EVENT_NAME.OPENED_SHOW
          : EVENT_NAME.HIDE,
        {
          [BaseEventProps.EVENT_CATEGORY]: "My Bets",
          [BaseEventProps.EVENT_LABEL]: "Show Details",
          [BaseEventProps.MODULE]: setFirstLetterCapital(
            get(data, "payload.selectedTab")
          ),
          [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
            get(data, "payload.selectedTab", ""),
            get(data, "payload.selectedSettledTab", "")
          ),
          [BaseEventProps.ACTIVE_BETS]: get(data, "payload.totalActiveBets", 0),
          [BaseEventProps.SETTLED_BETS]: get(
            data,
            "payload.totalSettledBets",
            0
          )
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SHOW_LEGS,
    (data: {
      payload: {
        isOpening: boolean;
        activeBets: number;
        settledBets: number;
      };
    }) => {
      track<BaseEventProps>(
        data.payload.isOpening
          ? EVENT_NAME.OPENED_SHOW_HIDE
          : EVENT_NAME.CLOSED_SHOW_HIDE,
        {
          [BaseEventProps.EVENT_CATEGORY]: "My Bets",
          [BaseEventProps.EVENT_LABEL]: "All Legs",
          [BaseEventProps.MODULE]: "Active",
          [BaseEventProps.FILTER_ACTIVE]: "None",
          [BaseEventProps.ACTIVE_BETS]: data.payload.activeBets,
          [BaseEventProps.SETTLED_BETS]: data.payload.settledBets
        }
      );
    }
  );

  mediator.base.subscribe(MediatorEventType.CANCEL_BET, (data: MyBetsBase) => {
    track<BaseEventProps>(EVENT_NAME.CANCEL_BET_OPEN_MODAL, {
      [BaseEventProps.EVENT_CATEGORY]: "My Bets",
      [BaseEventProps.EVENT_LABEL]: "Cancel Bet Confirmation",
      [BaseEventProps.MODULE]: get(data, "payload.selectedTab"),
      [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
        get(data, "payload.selectedTab", ""),
        get(data, "payload.selectedSettledTab", "")
      ),
      [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
      [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
    });
  });

  mediator.base.subscribe(
    MediatorEventType.RETURN_TO_BETTING,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.RETURN_BETTING_NAVIGATE_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.LINK_TEXT]: "Return to Betting",
        [BaseEventProps.MODULE]: get(data, "payload.selectedTab")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CLICKS_X_BUTTON,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.CLOSED_SCREEN, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: "My Bets",
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        ),
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        )
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.OPEN_TRACK_RULES,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.TRACK_RULES_OPENED_MODAL, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: "See Track Rules",
        [BaseEventProps.MODULE]: get(data, "payload.selectedTab"),
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        )
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.USER_SAW_WARNING_MESSAGE,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.SAW_MESSAGE, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.message", ""),
        [BaseEventProps.MODULE]: "my_bets",
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.USER_CLICKS_WATCH_REPLAY,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.WATCH_REPLAY_NAVIGATE_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.LINK_TEXT]: get(
          data,
          "payload.gaEventLabel",
          "Watch Replay"
        ),
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        )
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.USER_CLICKS_VIDEO_LIVE_BUTTON,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.LIVE_BUTTON_NAVIGATE_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.LINK_TEXT]: "watch_live",
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        )
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CLICKS_SEE_RULES_ON_CANCELLED_BET,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.CANCEL_BET_OPENED_MODAL, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: "See Rules",
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        ),
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.TAB_CLICK, (data: MyBetsBase) => {
    const myBetsBase = {
      [BaseEventProps.EVENT_CATEGORY]: "My Bets",
      [BaseEventProps.EVENT_LABEL]: setFirstLetterCapital(
        get(data, "payload.selectedTab", "")
      ),
      [BaseEventProps.MODULE]: "my_bets",
      [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
        get(data, "payload.selectedTab", ""),
        "TODAY"
      ),
      [BaseEventProps.TAB]: setFirstLetterCapital(
        get(data, "payload.selectedTab")
      ),
      [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
      [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
    };
    const dataLayer =
      get(data, "payload.selectedTab", "").toUpperCase() === "FUTURES"
        ? { ...myBetsBase, futureBets: get(data, "payload.futureBets", 0) }
        : myBetsBase;
    track<BaseEventProps>(EVENT_NAME.TAB_SELECT_OPEN_TAB, dataLayer);
  });

  mediator.base.subscribe(
    MediatorEventType.EXPAND_COLLAPSE_INDIVIDUAL_LEG,
    (data: MyBetsBase) => {
      track<BaseEventProps>(get(data, "payload.gaEventAction"), {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.legNumber"),
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        ),
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PICKBET_CREATE_BET,
    (data: { payload: MultiLegCreateBet }) => {
      track<BaseEventProps>(EVENT_NAME.CREATE_BET, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
        [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet"),
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(
          data,
          "payload.runnerSelectionList"
        ),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
        [BaseEventProps.MODULE]: "My Bets My Account"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.POTENTIAL_PAYOUT_CLICK,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.PAYOUT_OPENED_MODAL, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.EVENT_LABEL]: "Potential Payout",
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        ),
        [BaseEventProps.FILTER_ACTIVE]: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        [BaseEventProps.ACTIVE_BETS]: get(data, "payload.activeBets", 0),
        [BaseEventProps.SETTLED_BETS]: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.REPEAT_BET,
    (data: RepeatBetEventData) => {
      const eventAction = get(data, "payload.gaEventAction");
      if (eventAction) {
        track<BaseEventProps>(eventAction, {
          [BaseEventProps.EVENT_CATEGORY]: "My Bets",
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel"),
          [BaseEventProps.MODULE]: get(data, "payload.module"),
          [BaseEventProps.LINK_TEXT]: get(data, "payload.gaEventLabel")
        });
      } else {
        track<BaseEventProps>(EVENT_NAME.BET_SUCCESS, {
          [BaseEventProps.EVENT_CATEGORY]: "My Bets",
          [BaseEventProps.MODULE]: "Bet Modal",
          [BaseEventProps.RUNNER_SELECTION_LIST]: get(
            data,
            "payload.runnerSelectionList"
          ),
          [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
          [BaseEventProps.RACE_NUMBER_SELECTION]: get(
            data,
            "payload.raceNumber"
          ),
          [BaseEventProps.BET_ID]: get(data, "payload.betId"),
          [BaseEventProps.BET_AMOUNT]: get(data, "payload.betTotal"),
          [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
          [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet", 0),
          [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount"),
          [BaseEventProps.SELECTION_SOURCE]: get(
            data,
            "payload.selectionSource"
          )
        });
      }
    }
  );

  mediator.base.subscribe(
    MediatorEventType.NAVIGATE_TO_TRACK,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.TRACK_NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.LINK_TEXT]: "Program Page",
        [BaseEventProps.MODULE]: setFirstLetterCapital(
          get(data, "payload.selectedTab")
        ),
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );
  mediator.base.subscribe(
    MediatorEventType.ADD_TO_BETSLIP,
    (data: MyBetsBase) => {
      track<BaseEventProps>(EVENT_NAME.REPEAT_BET, {
        [BaseEventProps.EVENT_CATEGORY]: "My Bets",
        [BaseEventProps.LINK_TEXT]: "Program Page",
        [BaseEventProps.MODULE]: get(data, "payload.module"),
        [BaseEventProps.SELECTION_SOURCE]: get(data, "payload.selectionSource"),
        [BaseEventProps.SELECT_RACE_TYPE]: get(data, "payload.raceType"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount")
      });
    }
  );
};
