import tvgConf from "@tvg/conf";
import moment from "moment";
import requester from "../requester";

export type GetVideoStreamUrl = {
  isReplay?: boolean;
  streamName: string;
  referer?: string;
  isHd?: boolean;
  trackName: string;
  sessionUrl?: string;
  height?: string;
  width?: string;
  player?: string;
};

const getStreamServiceUrl = (isReplay: boolean = false) => {
  const subDomain = isReplay ? "//replays" : "//stream";
  const route = isReplay ? "racereplays/replaysflash.php" : "streamlive.php";

  return encodeURIComponent(`${subDomain}.robertsstream.com/${route}?`);
};

const getEnvUrl = () => {
  const { product } = tvgConf();
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "tvg.com";
  if (hostname.includes("racing")) {
    const hostnameWithoutLocal = hostname.replace("local.", "");
    return hostnameWithoutLocal;
  }

  const hostnameWithoutLocal = hostname.replace(
    "local-",
    product === "tvg" ? "www-" : ""
  );

  return hostnameWithoutLocal;
};

const getTvgVideoUrl = (src: string) => {
  const envUrl = getEnvUrl();
  return `https://${envUrl}/video.html?src=${src}`;
};

const getVideoStreamUrl = ({
  isReplay = false,
  streamName,
  referer = "TVG",
  isHd = false,
  trackName,
  sessionUrl = `//${tvgConf().config("service.usa")}/authenticated`,
  width = "640",
  height = "480",
  player = "iframe"
}: GetVideoStreamUrl): Promise<{ streamUrl: string; tvgUrl: string }> => {
  const envUrl = getEnvUrl();
  const config = tvgConf(envUrl).config();
  const timestamp = moment().utc().unix();

  return requester()({
    url: `https:${config.service.rcn}/generateHash`,
    method: "get",
    params: {
      streamname: streamName,
      timestamp
    },
    withCredentials: true
  }).then(({ data: { hash } }) => {
    const specificParams = isReplay ? { cust: referer } : { referer };

    const params = {
      t: `${timestamp}`,
      h: `${hash}`,
      stream: streamName,
      ...specificParams,
      hd: isHd ? "1" : "0",
      width: "100%",
      height: "100%",
      scrolling: "no"
    };

    // @ts-ignore do not want to risk to change this for now
    const encodedQueryString = encodeURIComponent(new URLSearchParams(params));
    const queryString = new URLSearchParams(encodedQueryString);

    queryString.append("name", trackName);
    queryString.append("w", width);
    queryString.append("h", height);
    queryString.append("player", player);
    queryString.append("session_url", sessionUrl);

    const streamUrl = `${getStreamServiceUrl(isReplay)}${queryString}`;

    return {
      streamUrl,
      tvgUrl: getTvgVideoUrl(streamUrl)
    };
  });
};

const RCN_SERVICE = {
  getStreamServiceUrl,
  getEnvUrl,
  getTvgVideoUrl,
  getVideoStreamUrl
};

export default RCN_SERVICE;
