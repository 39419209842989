export enum MediatorEventType {
  CONFIRM = "BET:BET_CONFIRM",
  ERROR = "BET:BET_ERROR",
  SUCCESS = "BET:BET_SUCCESS"
}

interface BasePayload {
  raceNumber?: string;
  trackName?: string;
  betType: string;
  betAmount: number;
  selectionSource?: string;
  raceType?: string;
}
export interface EventData<T> {
  type: MediatorEventType;
  payload: BasePayload & T;
}

export interface SuccessPayload {
  selections: string;
  runnerAmount: number;
  repeatBet?: number;
  betId: string;
  isRepeat: boolean;
  selectionSource?: string;
  raceType?: string;
}

export interface ErrorPayload {
  error: number;
  selections: string;
  runnerAmount: number;
}
