// @flow
import { createStore, compose, applyMiddleware } from "redux";
import type { Store } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
// $FlowFixMe
import promise from "redux-promise-middleware";

let composeEnhancers = compose;

// $FlowFixMe
if (typeof DEVELOPMENT !== "undefined" && DEVELOPMENT) {
  /* eslint-disable */
  composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */
}

export default (initialState: *): Store<*, *> => {
  const middlewares = [promise(), thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(reducers, initialState, enhancer);

  // $FlowFixMe
  if (module.hot) {
    // $FlowFixMe
    module.hot.accept("./reducers", () => {
      // $FlowFixMe
      return System.import("./reducers").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      );
    });
  }

  return store;
};
