import { startCase } from "lodash";
import { BaseEventProps } from "../../types";
import { TVG_PAGE_CONFIG, PAGE_NAME_KEYS } from "../../constants";

export const getTrackName = () => {
  const pathName = window.location.pathname;
  const trackName = pathName.split("/").pop();
  const evalPath = TVG_PAGE_CONFIG.find(
    (config) => config.page === PAGE_NAME_KEYS.TRACK_INFORMATION
  );

  if (!evalPath?.urlReg.test(pathName) || trackName === "racetracks") {
    return null;
  }

  return { [BaseEventProps.TRACK_NAME]: startCase(trackName) };
};
