import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import {
  fanduelColorTokens,
  tvgColorTokens
} from "../../src/theming/themes/tokens";
import { ThemeProps } from "./types";

import { colors, elevations, space, iconSizes } from "./tokens";

interface TVGThemeProviderProps {
  children: React.ReactNode;
  isFanduelTheme?: boolean;
}

const TVG_THEME = {
  name: "tvg",
  colors,
  elevations,
  space,
  iconSizes,
  colorTokens: tvgColorTokens
};

const TVGThemeProvider: FC<TVGThemeProviderProps> = ({
  children,
  isFanduelTheme
}) => {
  const colorTokens = isFanduelTheme ? fanduelColorTokens : tvgColorTokens;

  return (
    <ThemeProvider theme={{ ...TVG_THEME, colorTokens } as ThemeProps}>
      {children}
    </ThemeProvider>
  );
};

export { TVG_THEME, TVGThemeProvider, iconSizes };
