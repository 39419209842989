// @flow
import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import classNames from 'classnames';
import Modal from '@tvg/ui-bootstrap/components/Modal';
import style from "./utils.css";

const getRootElement = (id: string) => {
  let element = document && document.getElementById(id);

  if (!element) {
    element = document.createElement('div');
    element.id = id;
    // $FlowFixMe
    document.body.appendChild(element);
  }

  return element;
};

const openModal = (id: string, content: Object, customModal?: boolean = false, callback) => {
  const rootElement = getRootElement(id);
  unmountComponentAtNode(rootElement);

  const modalStyle = classNames({
    [style.loginModal]: true,
    [style.customModal]: customModal,
  });

  return ReactDOM.render(  // eslint-disable-line
    <Modal
      autoOpen
      classnames={modalStyle}
      modalIdentifier={id}
    >
      {content}
    </Modal>, rootElement, callback);
};

export default {
  getRootElement,
  openModal,
};
