import {
  GeolocationStatus,
  GeolocationRetryStatus,
  GeolocationRetryState,
  GeolocationError
} from "../types";
import { Actions } from "./types";

export interface State {
  geolocationMessageShown: boolean;
  geolocationStatus?: GeolocationStatus;
  geolocationError?: GeolocationError;
  isErrorRetryable?: boolean;
  geoComplyErrorOpen?: boolean;
  geolocationRetryStatus: GeolocationRetryStatus;
}

export const initialState = {
  geolocationMessageShown: false,
  geolocationStatus: undefined,
  geoComplyErrorOpen: false,
  geolocationError: {},
  isErrorRetryable: undefined,
  geolocationRetryStatus: {
    state: GeolocationRetryState.IDLE,
    attempts: 0
  }
};

export default (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case "GEOLOCATION_STATUS": {
      return { ...state, ...action.payload };
    }
    case "GEOLOCATION_MESSAGE_SHOWN": {
      return { ...state, ...action.payload };
    }
    case "GEOLOCATION_ERROR": {
      return { ...state, ...action.payload };
    }
    case "GEOLOCATION_STATUS_CLEAR": {
      return {
        ...state,
        geolocationMessageShown: false,
        geolocationStatus: undefined,
        geolocationError: {},
        isErrorRetryable: undefined
      };
    }
    case "SET_GEOLOCATION_RETRY_STATUS": {
      return {
        ...state,
        geolocationRetryStatus: action.payload
      };
    }
    case "TRY_GEOLOCATION_RETRY": {
      return {
        ...state,
        geolocationRetryStatus: {
          attempts:
            state.geolocationRetryStatus.attempts +
            Number(action.payload === GeolocationRetryState.REQUESTED),
          state: action.payload
        }
      };
    }
    case "GEOCOMPLY_ERROR_MODAL": {
      return {
        ...state,
        geoComplyErrorOpen: !state.geoComplyErrorOpen
      };
    }
    case "GEOLOCATION_RETRY_CLEAR": {
      return {
        ...state,
        geolocationRetryStatus: {
          state: GeolocationRetryState.IDLE,
          attempts: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};
