import { BaseEventProps } from "@tvg/amplitude";

export enum ModuleEventProps {
  BANNER_TYPE = "Banner Type",
  BANNER_ID = "Banner Id",
  BANNER_NAME = "Banner Name"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  HEADER_CLICK = "DERBY_HEADER_CLICK",
  HEADER_SELECTED_RACE = "DERBY_HEADER_SELECTED_RACE",
  HEADER_VIEWED = "DERBY_HEADER_VIEWED"
}

export type DerbyHeaderClickEventData = {
  type: MediatorEventType.HEADER_CLICK | MediatorEventType.HEADER_SELECTED_RACE;
  payload: {
    linkText: string;
    linkUrl?: string;
    bannerId: string;
    bannerName: string;
  };
};

export type DerbyHeaderViewedEventData = {
  type: MediatorEventType.HEADER_VIEWED;
  payload: {
    bannerId: string;
    bannerName: string;
  };
};
