import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";

const userDownloadUrl = (accountId: number, fileName: string) =>
  `${getProtocol()}${
    tvgConf().config().service.gasUsers
  }/${accountId}/download?filename=${fileName}`;

const GAS_SERVICE = {
  userDownloadUrl
};

export default GAS_SERVICE;
