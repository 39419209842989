import { combineReducers } from "redux";
import revalidateLocation from "@tvg/revalidate-location/reducer";
import loginControllerReducers from "@tvg/login-controller/src/reducers";
import brazeData from "@tvg/braze/src/store/reducers";
import locationSplash from "@tvg/location-splash/reducer";
import login from "./login";
import balance from "./balance";
import header from "./header";
import nextRace from "./nextRace";
import accountButton from "./accountButton";

export default combineReducers({
  login,
  balance,
  header,
  locationSplash,
  ...loginControllerReducers,
  brazeData,
  nextRace,
  accountButton,
  revalidateLocation
});
