import mediator from "@tvg/mediator";
import { MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.WAGERPAD_CLICK, () => {
    track<BaseEventProps>(EVENT_NAME.BETSLIP_OPENED, {
      [BaseEventProps.EVENT_CATEGORY]: "Betslip",
      [BaseEventProps.MODULE]: "Global Header"
    });
  });
};
