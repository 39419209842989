import axios from "axios";
import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";

const FDRService = "service.fdr";
const FDRServiceUrl = tvgConf().config(FDRService);
const context = tvgConf().context();

const createFDUser = (
  email: string,
  username: string,
  password: string,
  promoCode?: string
) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/users/fanduel-account`,
    headers: { "x-tvg-context": context },
    data: {
      email,
      username,
      password,
      promoCode
    },
    withCredentials: true
  });

const getTVGUserDetails = () =>
  axios({
    method: "GET",
    url: `${getProtocol()}${FDRServiceUrl}/users`,
    headers: { "x-tvg-context": context },
    withCredentials: true
  });

const requestLogin = (email: string, password: string, stateAbbr: string) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/login`,
    headers: { "x-tvg-context": context },
    data: {
      email,
      password,
      stateAbbr
    },
    withCredentials: true
  });

/**
 * Logouts user with any of 4 tokens
 * @param token - fdrAccessToken | fdrRefreshToken | tvgFanduelToken | tvg3Token
 */
const requestLogout = (token?: string) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/logout`,
    headers: { "x-tvg-context": context },
    data: token,
    withCredentials: true
  });

const createFacebookPassword = (email: string) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/users/create-password`,
    headers: { "x-tvg-context": context },
    data: {
      email
    }
  });

const resetPassword = (email: string) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/users/reset-password`,
    headers: { "x-tvg-context": context },
    data: {
      email
    }
  });

const requestResetPasswordChallenge = (token: string) =>
  axios({
    method: "GET",
    url: `${getProtocol()}${FDRServiceUrl}/users/reset-password/challenge`,
    headers: { "x-tvg-context": context },
    params: {
      token
    }
  });

const requestChangePassword = (
  token: string,
  password: string,
  mfaCode?: string
) => {
  const data: {
    token: string;
    password: string;
    mfaCode?: string;
  } = {
    token,
    password
  };

  if (mfaCode) {
    data.mfaCode = mfaCode;
  }

  return axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/users/change-password`,
    headers: { "x-tvg-context": context },
    data
  });
};

const requestLoginMFA = (
  challengeToken: string,
  mfacode: string,
  stateAbbr: string
) =>
  axios({
    method: "POST",
    url: `${FDRServiceUrl}/login`,
    headers: { "x-tvg-context": context, "authentication-type": "mfa" },
    data: {
      challengeToken,
      mfacode,
      stateAbbr
    },
    withCredentials: true
  });

const requestSMS = (challengeToken: string) =>
  axios({
    method: "POST",
    url: `${getProtocol()}${FDRServiceUrl}/users/mfa/sms`,
    headers: { "x-tvg-context": context },
    data: {
      challengeToken
    }
  });

const FDR_SERVICES = {
  getTVGUserDetails,
  createFacebookPassword,
  resetPassword,
  createFDUser,
  requestLogin,
  requestLogout,
  requestLoginMFA,
  requestResetPasswordChallenge,
  requestChangePassword,
  requestSMS
};

export default FDR_SERVICES;
