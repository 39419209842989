import { get } from "lodash";
import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  ModuleEventProps,
  PageViewData,
  SiteClickData,
  iOSTrackingPermissionData
} from "./types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";
import { BaseEventProps } from "../../types";
import { getTrackName } from "./utils";

export default () => {
  mediator.base.subscribe(MediatorEventType.PAGE_VIEW, (data: PageViewData) => {
    const page: string = get(data, "payload.page");
    let isContentCardsInboxShown = false;

    if (page && page.indexOf("/unsubscribe") >= 0) {
      return;
    }

    if (page) {
      isContentCardsInboxShown =
        page.indexOf("/racetracks") !== 0 &&
        page.indexOf("/live") !== 0 &&
        !page.includes("#");
    }

    track<EventProps>(COMMON_EVENT_NAMES.PAGE_VIEWED, {
      ...(isContentCardsInboxShown
        ? {
            [BaseEventProps.PROMO_CODE]: get(
              data,
              "payload.promoCode",
              undefined
            )
          }
        : null),
      ...getTrackName()
    });
  });

  mediator.base.subscribe(MediatorEventType.HOME_NAVIGATION, () => {
    track<EventProps>(EVENT_NAME.HOME_NAVIGATION, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: "Home Page",
      [BaseEventProps.MODULE]: "Header",
      [BaseEventProps.LINK_URL]: "/home"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SITE_CLICK,
    (data: SiteClickData) => {
      track<EventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.IOS_TRACKING_PERMISSION,
    (data: iOSTrackingPermissionData) => {
      track<EventProps>(EVENT_NAME.IOS_TRACKING_PERMISSION, {
        [BaseEventProps.EVENT_CATEGORY]: "Permission",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [ModuleEventProps.DEVICE]: get(data, "payload.device"),
        [ModuleEventProps.OS_VERSION]: get(data, "payload.osVersion"),
        [ModuleEventProps.APP_VERSION]: get(data, "payload.appVersion")
      });
    }
  );
};
