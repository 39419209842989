// @flow
import React, { Component } from 'react';
import { get, noop, bindAll } from 'lodash';
import { buildRedirectMessage } from '@tvg/login-service/services/helper';

import style from "./style.css";

type Props = {
  targetDomain?: ?string,
  closeModal?: () => void,
  type: string,
  cmsMsgs: { [string]: string }
};

class CustomModal extends Component {
  static defaultProps = {
    targetDomain: '',
    closeModal: noop,
    type: '',
    cmsMsgs: {}
  };

  constructor(props: Props) {
    super(props);

    bindAll(this, [
      'redirectModal',
      'sessionExpiredModal',
    ]);
  }

  redirectModal() {
    const { redirectMessage, redirectTitle } = buildRedirectMessage(
      this.props.targetDomain,
      get(this.props.cmsMsgs, "loginRedirectionMessage", "{}")
    );

    return (
      <div className={style.loginRedirect}>
        <h2 className={style.header} data-qa-label="loginRedirect-title">
          {redirectTitle}
        </h2>
        <div className={style.body}>
          <span className={style.text} data-qa-label="loginRedirect-text">
            {redirectMessage}
          </span>
          <a href={window.location.protocol + '//' + get(this.props, 'targetDomain')} className={style.button} data-qa-label="loginRedirect-button">
            Ok
          </a>
        </div>
      </div>
    )
  }

  sessionExpiredModal() {
    return (
      <div className={style.loginRedirect}>
        <h2 className={style.header}>
          Logout
        </h2>
        <div className={style.body}>
          <span className={style.text}>
            Your session has expired and you were automatically logged out. Please login again.
          </span>
          <button onClick={this.props.closeModal} className={style.button}>Ok</button>
        </div>
      </div>
    )
  }

  render() {
    switch (this.props.type) {
      case 'redirect': return this.redirectModal();
      case 'sessionExpired': return this.sessionExpiredModal();
      default: break;
    }
  }
}

export default CustomModal;
