// @flow

import type { BrazeContentCard } from "@tvg/braze/src/store/actions";

export type WagerProfile =
  | "PORT-Generic"
  | "PORT-FL"
  | "PORT-NJ"
  | "PORT-PA"
  | "PORT-IA"
  | "FDR-Generic";

export type Address = {
  streetNumber: string,
  address1: string,
  address2: string,
  city: string,
  stateAbbr: string
};

export type AccountRolesEnum = "USER" | "TALENT" | "WAGEROPS" | "ADMIN";
// eslint-disable-next-line
export const RolesMap = {
  TALENT: "TALENT",
  WAGEROPS: "WAGEROPS",
  ADMIN: "ADMIN"
};

export type UserInfo = {
  accountNumber: string,
  emailAddress: string,
  firstName: string,
  homeAddress: Address,
  lastName: string,
  mailingAddress: Address,
  primaryPhone: string,
  profile: string,
  signalProviderId: string,
  status: string,
  transportId: string,
  userName: string,
  wagerStatus: number,
  accountRoles: Array<AccountRolesEnum>
};

export type User = {
  logged: boolean,
  user: UserInfo
};

export type UserPromo = {
  id: number,
  optedIn: boolean,
  path: string
};

export type UserOptedInPromos = {
  [string]: boolean
};

export type FavoriteTrackWithId = {
  [key: string]: string
};

export type BrazeCardNumber = {
  unviewed: number,
  totalCards: number,
  cards: BrazeContentCard[]
};

export type SSNObject = {
  firstName: string,
  lastName: string,
  yearOfBirth: number,
  monthOfBirth: number,
  dayOfBirth: number,
  address: string,
  zipCode: string,
  lastFourSSN: string
};

export type AddressParam = {
  streetNumber: string,
  address1: string,
  address2: string,
  zipCode: string,
  city: string,
  state: string,
  country: "United States"
};

export type UserDetails = {
  username: string,
  password: string,
  email: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  homeAddress: AddressParam,
  mailAddress: AddressParam,
  sameMailingAddress: boolean,
  socialSecurityNumber: string,
  phone: string,
  subscriptions: {
    email: boolean
  },
  signalProvider: {
    name: string,
    providerId: string
  }
};

export type SignupDetails = {
  promoCode: string,
  campaignCode: string,
  rfr: string
};
