// @flow

import React, {Component} from 'react';
import classNames from 'classnames';
import TVGConf from '@tvg/conf';
import IconClose from '@tvg/ui-bootstrap/assets/svg/close.svg';
import style from "./style.css";

type Props = {
  closeModal?: () => void,
};

export const getLogoStyle = (brand: string) => {
  switch (brand) {
    case '4njbets': return style.njLogo;
    case 'pabets': return style.paLogo;
    case 'iowa': return style.iaLogo;
    default: return style.baseLogo;
  }
};

const logo = getLogoStyle(TVGConf().brand);

const Header = (props: Props) => (
  <div className={style.loginSignupHeader}>
    <div className={classNames(style.loginSignupHeaderLogo, logo)} />

    <div className={style.loginSignupHeaderCloseBlock}>
      <button
        onClick={props.closeModal}
        className={style.closeIcon}
      >
        <IconClose className={style.loginSignupHeaderCloseIcon} />
      </button>
    </div>
  </div>
);

export default Header;
