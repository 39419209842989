import { get, camelCase, startCase } from "lodash";

import tvgConf from "@tvg/conf";
import {
  GlobalProperties,
  GlobalPropertiesArgs,
  LoginStatusValues,
  PathnameHashAndSearch
} from "./types";
import { PAGE_NAMES, TVG_PAGE_CONFIG } from "./constants";

export const setFirstLetterCapital = (tab: string) =>
  tab ? startCase(camelCase(tab)) : undefined;

export const convertMyBetsFilterActive = (
  selectedTab: string,
  selectedSettledTab: string
) => {
  if (["ACTIVE", "FUTURES"].includes(selectedTab.toUpperCase())) {
    return "None";
  }

  const isPastDayFilters =
    selectedSettledTab &&
    ["YESTERDAY", "LAST_WEEK", "LAST_MONTH"].includes(
      selectedSettledTab.toUpperCase()
    );

  return isPastDayFilters
    ? `Since ${setFirstLetterCapital(selectedSettledTab)}`
    : setFirstLetterCapital(selectedSettledTab) || "";
};

export const processTrackName = (trackName: string): string =>
  trackName.replace(" - ", " ");

export const parseModule = (module: string) =>
  module
    .split("_")
    .map((word) => word[0] + word.slice(1).toLowerCase())
    .join(" ")
    .replace("Az", "AZ");

export const buildModule = (isMyBets: boolean, isActiveBet: boolean) => {
  if (!isMyBets) {
    return "Bet Successful";
  }

  return isActiveBet ? "myBets - Active" : "myBets - Settled";
};

export const getPageName = (
  location: PathnameHashAndSearch,
  config = TVG_PAGE_CONFIG
) => {
  if (!location) return "";
  const { pathname, hash, search } = location;
  const fullPath = pathname + search + hash;

  if (fullPath === "/") return "home";

  const pageName = config.find(
    (elem) => fullPath.search(elem.urlReg) !== -1
  )?.page;

  if (pageName) return pageName;

  // If the page name is not available on the 'config' object
  // return the last page path level (with or without the hash, if available)
  const lastPagePathLevel = pathname?.split("/").pop();

  if (!lastPagePathLevel && hash) {
    return startCase(hash.replace("#", ""));
  }

  return hash
    ? `${lastPagePathLevel}:${startCase(hash.replace("#", ""))}`
    : lastPagePathLevel;
};

export const getPageViewVal = (pageName = "", config = PAGE_NAMES) => {
  let currentPage = get(config, pageName);

  if (!currentPage) {
    const pageFragments = pageName.split(":");

    pageFragments[0] = pageFragments[0] || "Home";
    const formattedFragments = pageFragments.map((fragment) =>
      startCase(fragment)
    );
    currentPage = formattedFragments.join(":");
  }

  return `${currentPage} Page Viewed`;
};

export const buildGlobalProperties = ({
  balance,
  isLogged,
  jurisdiction,
  location,
  pageTitle,
  pageConfig
}: GlobalPropertiesArgs) => {
  const { product, siteVersion, sitePlatform, androidDistributionMethod } =
    tvgConf().amplitudeConfig();

  return {
    [GlobalProperties.BALANCE]: isLogged ? balance?.toString() : null,
    [GlobalProperties.FULL_URL]: window?.location.href,
    [GlobalProperties.HOSTNAME]: window?.location.hostname,
    [GlobalProperties.JURISDICTION]: jurisdiction?.toLowerCase(),
    [GlobalProperties.LOGIN_STATUS]: isLogged
      ? LoginStatusValues.LOGGED_IN
      : LoginStatusValues.LOGGED_OUT,
    [GlobalProperties.PAGE_NAME]: getPageName(location, pageConfig) || "",
    [GlobalProperties.PAGE_PATH]:
      `${location.pathname}${location.search}` || "",
    [GlobalProperties.PAGE_TITLE]: pageTitle,
    [GlobalProperties.PRODUCT]: product,
    [GlobalProperties.SITE_PLATFORM]: sitePlatform,
    [GlobalProperties.SITE_VERSION]: siteVersion,
    ...(androidDistributionMethod
      ? {
          [GlobalProperties.ANDROID_DISTRIBUTION]: androidDistributionMethod
        }
      : {})
  };
};

// This events comes as payload action
export const EVENTS_TO_EXCLUDE = ["wager now", "bet now", "join now"];

export const getPromoLevel = (
  raceHasPromos: boolean,
  trackHasPromos: boolean
) => {
  if (raceHasPromos) {
    return "race_level";
  }

  if (trackHasPromos) {
    return "track_level";
  }

  return "none";
};
