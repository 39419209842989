import tvgConf from "@tvg/conf";
import { UserData } from "@tvg/ts-types/Login";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const usaService: string = "service.usa";

type PostData = {
  account: string;
  pin: string;
  stateAbbr: string;
  geo?: string;
  group?: string;
  verified?: boolean;
  enabled?: boolean;
};

const tryUserLogin = (loginData: UserData) => {
  const postData: PostData = {
    account: loginData.username,
    pin: loginData.password,
    stateAbbr: loginData.state
  };

  if (loginData.geo) {
    postData.geo = loginData.geo;
  }

  if (loginData.group) {
    postData.group = loginData.group as string;
  }

  if (loginData.verified) {
    postData.verified = loginData.verified;
  }

  if (loginData.enabled) {
    postData.enabled = loginData.enabled;
  }

  return requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(usaService)}/login`,
    data: postData,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });
};

const tryUserLogout = (tvg3Token: string) =>
  requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(usaService)}/logout`,
    data: tvg3Token,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });

const USA_SERVICE = {
  tryUserLogin,
  tryUserLogout
};

export default USA_SERVICE;
