import type { BrazeActions } from "./actions";
import { BrazeContentCard } from "../types";

export type State = {
  brazeContentCards: {
    unviewed: number;
    totalCards: number;
    cards: BrazeContentCard[];
  };
  brazePoller: number;
};

export const initialState = {
  brazeContentCards: {
    unviewed: 0,
    totalCards: 0,
    cards: []
  },
  brazePoller: 120
};

const brazeReducer = (state: State = initialState, action: BrazeActions) => {
  switch (action.type) {
    case "SET_BRAZE_CONTENT_CARDS": {
      return { ...state, brazeContentCards: action.payload };
    }
    case "DISMISS_BRAZE_CONTENT_CARD": {
      return {
        ...state,
        brazeContentCards: state.brazeContentCards.cards.filter(
          (card) => card.id === action.payload.id
        )
      };
    }
    case "BRAZE_TIMER_POLLER": {
      return { ...state, brazePoller: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default brazeReducer;
