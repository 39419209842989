import axios from "axios";
import ServiceUrl from "../../factories/serviceUrl";
import errorMessages from "./errorMessages.json";

const validateGeoPacket = (userId, geo) =>
  axios({
    method: "POST",
    url: `${ServiceUrl.getServiceUrl("service.geo")}/geopacket`,
    headers: {
      Accept: "application/json",
      "x-tvg-context": ServiceUrl.getContext()
    },
    data: {
      geo,
      userId
    }
  });

export default class GeoComplySvc {
  constructor() {
    this.geoClient = null;
    this.geoLicense = null;

    this.getPack = this.getPack.bind(this);
    this.getLicence = this.getLicence.bind(this);
    this.getGeoPacket = this.getGeoPacket.bind(this);
    this.clientEmitMessage = this.clientEmitMessage.bind(this);
    this.validateGeoPacket = validateGeoPacket;
    this.softErrorMessage = "geocomply browser error";

    if (typeof window !== "undefined") {
      this.geoComplyIsPresent = window.GcHtml5;

      if (!this.geoComplyIsPresent) {
        console.log(errorMessages.missingGeo); // eslint-disable-line
      } else {
        this.geoClient = window.GcHtml5.createClient(null, null);
        this.geoClient.setReason("GeoComply login for TVG");

        /*
         *  SUCCESS Scenario
         */

        // Register the onSuccess handler
        this.geoClient.events.on("engine.success", (text, xml) => {
          this.clientEmitMessage({ success: { text, xml } });
        });

        /*
         *  ERROR Scenarios
         */
        // Register browser error handler
        this.geoClient.events.on("browser.failed", (code) => {
          this.clientEmitMessage({
            error: { code, message: this.softErrorMessage }
          });
        });
        // Register revised error handler
        this.geoClient.events.on("revised.failed", (code) => {
          this.clientEmitMessage({
            error: { code, message: this.softErrorMessage }
          });
        });
        // Register config error handler
        this.geoClient.events.on("config.failed", (code, message) => {
          this.clientEmitMessage({ error: { code, message } });
        });
        // Register engine error handler
        this.geoClient.events.on("engine.failed", (code, message) => {
          this.clientEmitMessage({ error: { code, message } });
        });
      }
    }
  }

  clientEmitMessage(answer) {
    this.geoClient.events.emit("geoPacketAvailable", answer);
  }

  getGeoPacket(userId, type = "login") {
    if (!this.geoComplyIsPresent || ServiceUrl.getBrand() !== "4njbets") {
      return Promise.reject({});
    }

    if (!this.geoClient) {
      this.geoClient = window.GcHtml5.createClient(null, null);
    }

    if (type === "revalidate") {
      this.geoClient.setReason("GeoComply revalidate for TVG");
    } else {
      // type === "login
      this.geoClient.setReason("GeoComply login for TVG");
    }

    if (!this.geoLicense) {
      return this.getLicence(userId).then(this.getPack);
    }

    return this.getPack(userId);
  }

  getLicence(userId) {
    return axios({
      method: "get",
      url: `${ServiceUrl.getServiceUrl("service.geo")}/license`,
      headers: {
        Accept: "application/json",
        "x-tvg-context": ServiceUrl.getContext()
      }
    })
      .then((response) => {
        this.geoLicense = response.data.license;
        return userId;
      })
      .catch(() => {
        this.geoLicense = null;
        return undefined;
      });
  }

  getPack(userId) {
    if (!this.geoClient || !this.geoLicense) {
      return Promise.reject(errorMessages.missingGeo);
    }

    if (!userId) {
      return Promise.reject(errorMessages.missingUserID);
    }

    return new Promise((resolve, reject) => {
      this.geoClient.events.once("geoPacketAvailable", (data) => {
        if (data.success) {
          resolve({ geo: data.success.text });
        } else {
          reject(data.error);
        }
      });

      this.geoClient.setUserId(userId.trim().toLowerCase());
      this.geoClient.setLicense(this.geoLicense);
      this.geoClient.request();
    });
  }
}
