// @flow
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import WarningIcon from '@tvg/ui-bootstrap/assets/svg/warning.svg';
import OkIcon from '@tvg/ui-bootstrap/assets/svg/success.svg';
import style from "./style.css";

type Props = {
  updateMainState: (par: {
    passwordValue: string
  }) => void,
  isPasswordValid: boolean,
  passwordValue: string,
  isPasswordCorrected: boolean,
  firstTouchForGTM: () => void,
  keyIsPressed: (e: SyntheticEvent<>) => void,
  onFieldBlur: (field: string, value: string) => void,
};

class Password extends Component {

  constructor(props: Props) {
    super(props);

    this.state = {
      isPassword: true,
    };

    _.bindAll(this, [
      'onChangePasswordHolder',
      'showClickHandler',
      'renderLabel',
      'renderInput',
      'gtmFieldComplete',
      'renderWarningMsg',
    ]);
  }

  state: {
    isPassword: boolean
  };

  input: ?HTMLInputElement;

  onChangePasswordHolder(e: Event & { target: HTMLButtonElement }): void {
    const passwordValue = e.target.value;
    this.props.updateMainState({ passwordValue });

    if (!this.props.isPasswordValid && this.checkPasswordPattern(passwordValue)) {
      this.props.updateMainState({
        isPasswordValid: true,
        isPasswordCorrected: true,
        passwordValue,
      });
    }

    if (this.props.isPasswordCorrected && !this.checkPasswordPattern(passwordValue)) {
      this.props.updateMainState({
        isPasswordValid: false,
        isPasswordCorrected: false,
        passwordValue,
      });
    }

    if (!this.checkPasswordPattern(passwordValue)) {
      this.props.updateMainState({
        isPasswordValid: false,
        isPasswordCorrected: false,
        passwordValue,
      });
    }
  }

  checkPasswordPattern: (value: string) => boolean;
  checkPasswordPattern(password: string) {
    return password.length > 0 &&
    password.length < 21 && /^[\\S]*/.test(password);
  }

  showClickHandler: () => void;
  showClickHandler(e: MouseEvent) {
    e.preventDefault();
    this.setState({
      isPassword: !this.state.isPassword,
    });
  }

  gtmFieldComplete: () => void;
  gtmFieldComplete() {
    this.props.onFieldBlur('password', this.props.passwordValue);
  }

  renderLabel: () => void;
  renderLabel() {
    const WarningFeedbackIcon = !this.props.isPasswordValid &&
      <WarningIcon
        className={
          classNames({
            [style.statusIcon]: true,
            [style.warningIcon]: true,
          })
        }
      />;

    const OkFeedbackIcon = this.props.isPasswordCorrected && this.props.isPasswordValid &&
      <OkIcon
        className={
          classNames({
            [style.statusIcon]: true,
            [style.warningIcon]: true,
          })
        }
      />;

    return (
      <label htmlFor="passwordInput" className={style.label}>
        Password/PIN{WarningFeedbackIcon || OkFeedbackIcon}
      </label>
    );
  }

  renderInput: () => void;
  renderInput() {
    const inputStyle = classNames({
      [style.inputPassword]: true,
      [style.inputPasswordInvalid]: !this.props.isPasswordValid,
    });

    return (
      <div className={style.inputWraper}>
        <input
          ref={c => this.input = c}
          type={this.state.isPassword ? 'password' : ''}
          id="passwordInput"
          data-qa-label="passwordInput"
          placeholder="Password"
          value={this.props.passwordValue}
          onChange={this.onChangePasswordHolder}
          className={inputStyle}
          name="password"
          onFocus={this.props.firstTouchForGTM}
          onBlur={_.throttle(this.gtmFieldComplete, 1000, { trailing: false })}
          onKeyPress={this.props.keyIsPressed}
        />
        <button
          onClick={this.showClickHandler}
          className={style.buttonShow}
        >
          {this.state.isPassword ? 'Show' : 'Hide'}
        </button>
      </div>
    );
  }

  renderWarningMsg: () => void;
  renderWarningMsg() {
    return !this.props.isPasswordValid &&
      <div className={style.warningMessage}>
        Please insert a valid Password/PIN.
      </div>;
  }

  render() {
    return (
      <div className={style.container}>
        {this.renderLabel()}
        {this.renderInput()}
        {this.renderWarningMsg()}
      </div>
    );
  }

}

export default Password;

